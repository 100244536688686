import React from "react";
import Routes from "./Routes";
import { BrowserRouter as Router } from "react-router-dom";
import { SnackbarProvider } from 'notistack';
import ScrollToTop from "hooks/ScrollToTop";

function App() {
  return (
    <>
    <Router>
      <SnackbarProvider 
      maxSnack={3}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      >
        <Routes />
        <ScrollToTop/>
      </SnackbarProvider>
    </Router>
    </>
  );
}

export default App;
