import { Heading, Img } from "../../components";
import React from "react";

export default function ServicesSection() {
  return (
    <>
      {/* services section */}
      <div className="flex flex-col gap-[100px] lg:gap-[70px] md:gap-[60px] ">
        <div className="flex items-center gap-[50px] md:flex-col">
          <Img
            src="images/img_img_600x910.png"
            alt="Product Image"
            className="h-[600px] w-[48%] rounded-[10px] md:object-contain sm:object-cover md:w-full"
          />
          <div className="flex flex-1 flex-col items-start gap-1 md:self-stretch md:px-5">
            <Heading
              size="heading2xl"
              as="h2"
              className="text-[30px] font-semibold text-blue_gray-900 lg:text-[25px] md:text-[24px] sm:text-[22px]"
            >
              Personalised Care Plans
            </Heading>
            <Heading
              as="h3"
              className="w-[66%] text-[16px] font-normal leading-[22px] text-gray-600 lg:w-full lg:text-[13px] md:w-full"
            >
              We create care plans that address the unique needs of each resident. Our team conducts thorough assessments to ensure that every aspect of personal, medical, and social care is considered. This approach empowers residents to maintain their dignity and independence while receiving the support they need.
              <br />
              <br />
              <ul className="list-disc pl-5">
                <li>Comprehensive assessments tailored to individual needs</li>
                <li>Regular reviews to adapt care plans as required</li>
                <li>Focus on promoting independence and dignity</li>
                <li>Collaboration with families for holistic care</li>
                <li>Integration of medical, social, and emotional support</li>
                <li>Continuous monitoring to ensure optimal outcomes</li>
              </ul>
            </Heading>
          </div>
        </div>
        <div className="flex items-center justify-end gap-[51px] md:flex-col">
          <div className="flex w-[34%] flex-col items-start gap-0.5 md:w-full md:px-5">
            <Heading
              size="heading2xl"
              as="h2"
              className="text-[30px] font-semibold text-blue_gray-900 lg:text-[25px] md:text-[24px] sm:text-[22px]"
            >
              24/7 Nursing Support
            </Heading>
            <Heading as="h3" className="w-full text-[16px] font-normal leading-[22px] text-gray-600 lg:text-[13px]">
              Our highly trained nursing staff is available around the clock to provide medical care and support.
              This service includes medication management, health monitoring, and immediate assistance in case of emergencies.
              Our goal is to ensure residents feel safe and cared for at all times.
              <br />
              <br />
              <ul className="list-disc pl-5">
                <li>Qualified nursing staff available around the clock</li>
                <li>Medication management and administration</li>
                <li>Regular health assessments and monitoring</li>
                <li>Immediate response to medical emergencies</li>
                <li>Support for chronic conditions and rehabilitation</li>
                <li>Coordination with healthcare professionals and specialists</li>
              </ul>
            </Heading>
          </div>
          <Img
            src="images/img_img_1.png"
            alt="Vector Image"
            className="h-[600px] w-[48%] rounded-[10px] md:object-contain sm:object-cover md:w-full"
          />
        </div>
        <div className="flex items-center gap-[50px] md:flex-col">
          <Img
            src="images/img_img_2.png"
            alt="Section Image"
            className="h-[600px] w-[48%] rounded-[10px] md:object-contain sm:object-cover md:w-full"
          />
          <div className="flex flex-1 flex-col items-start gap-1 md:self-stretch md:px-5">
            <Heading
              size="heading2xl"
              as="h2"
              className="text-[30px] font-semibold text-blue_gray-900 lg:text-[25px] md:text-[24px] sm:text-[22px]"
            >
              Social and Recreational Activities
            </Heading>
            <Heading
              as="h3"
              className="w-[66%] text-[16px] font-normal leading-[22px] text-gray-600 lg:w-full lg:text-[13px] md:w-full"
            >
              <>
                We believe in the importance of social interaction and mental stimulation for overall well-being. Our
                care home offers a variety of engaging activities, including arts and crafts, exercise classes, and
                community outings. These activities are designed to encourage socialisation and enhance the quality of
                life for our residents.
                <br />
                <br />
                <ul className="list-disc pl-5">
                  <li>Diverse activity programmes to cater to different interests</li>
                  <li>Scheduled outings to local attractions and events</li>
                  <li>Exercise classes to promote physical health</li>
                  <li>Arts and crafts sessions for creative expression</li>
                  <li>Group games and activities to encourage socialisation</li>
                  <li>Opportunities for residents to share their skills and hobbies</li>
                </ul>
              </>
            </Heading>
          </div>
        </div>
        <div className="flex items-center justify-end gap-[51px] md:flex-col">
          <div className="flex w-[34%] flex-col items-start md:w-full md:px-5">
            <Heading
              size="heading2xl"
              as="h2"
              className="text-[30px] font-semibold text-blue_gray-900 lg:text-[25px] md:text-[24px] sm:text-[22px]"
            >
              Family Support and Education
            </Heading>
            <Heading as="h3" className="w-full text-[16px] font-normal leading-[22px] text-gray-600 lg:text-[13px]">
              <>
                We recognise that families play a crucial role in the care process. Our services include family
                consultations and educational workshops that provide guidance on best practices for supporting elderly
                loved ones. We foster open communication and collaboration to ensure families feel confident and
                involved in the care journey.
                <br />
                <br />
                <ul className="list-disc pl-5">
                  <li>Family consultations to discuss care needs and concerns</li>
                  <li>Educational workshops on elderly care best practices</li>
                  <li>Regular updates and communication with family members</li>
                  <li>Resources for navigating healthcare and support services</li>
                  <li>Support groups for families to share experiences</li>
                  <li>Guidance on managing transitions and end-of-life care</li>
                </ul>
              </>
            </Heading>
          </div>
          <Img
            src="images/img_img_3.png"
            alt="Product Image"
            className="h-[600px] w-[48%] rounded-[10px] md:object-contain sm:object-cover md:w-full"
          />
        </div>
      </div>
    </>
  );
}
