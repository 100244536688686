import Footer from "../../components/Footer";
import Header from "../../components/Header";
import TermsAndConditionsSection from "./TermsAndConditionsSection";
import React from "react";

export default function TermsandConditionsPage() {
  return (
    <>
      <div className="flex w-full flex-col gap-[120px] bg-white-a700 lg:gap-[90px] md:gap-[90px] sm:gap-[60px]">
        <div className="h-[462px] bg-[url(/public/images/img_breadcrumbs_1.png)] bg-cover bg-no-repeat lg:h-auto md:h-auto">
          <div className="container-xs mb-[300px] flex flex-col gap-[354px] lg:gap-[265px] lg:px-5 md:gap-[265px] md:px-5 sm:gap-[177px]">
            <Header className="py-[26px] sm:flex-col sm:py-4" />
          </div>
        </div>

        {/* terms and conditions section */}
        <TermsAndConditionsSection />
        <Footer />
      </div>
    </>
  );
}
