import { Input, Heading, Img, Button } from "../../components";
import Footer from "../../components/Footer";
import React from "react";
import { useGetStarted } from "./useGetStarted";
import { Link, useLocation } from "react-router-dom";

export default function GetStartedPage() {
  const { submitForm, handleChange, formData, validationErrors } =
    useGetStarted();
  const handleSubmit = (e: any) => {
    e.preventDefault();
    submitForm();
  };
  const navItems = [
    { name: "Home", to: "/", textClass: "text-amber-700" },
    { name: "About Us", to: "/about", textClass: "text-white-a700 hover:text-amber-700" },
    { name: "Services", to: "/services", textClass: "text-white-a700 hover:text-amber-700" },
    { name: "Contact Us", to: "/contacts", textClass: "text-white-a700 hover:text-amber-700" },
  ];
  const location = useLocation()?.pathname;
  return (
    <>
      <div className="flex w-full flex-col gap-[120px] bg-white-a700 lg:gap-[90px] md:gap-[90px] sm:gap-[60px]">
        <div>
          <div className="flex flex-col items-center">
            <div className="h-[462px] self-stretch bg-[url(/public/images/img_mask_group_462x1920.png)] bg-cover bg-no-repeat lg:h-auto md:h-auto">
              <header className="flex items-start justify-center bg-blue_gray-900_b2 py-[26px] sm:py-4">
                <div className="container-xs mb-[352px] flex items-center justify-between gap-5 lg:px-5 md:flex-col md:px-5">
                  <Img
                    src="images/img_header_logo.svg"
                    alt="Header Logo"
                    className="h-[40px] w-[186px] object-contain"
                  />
                  <div className="flex w-[70%] items-center justify-between gap-5 md:w-full md:flex-col">
                    <div className="flex">
                      <ul className="flex flex-wrap gap-[90px] md:gap-5">
                        {navItems.map((item, index) => (
                          <li key={index}>
                            <Link to={item.to} className="cursor-pointer">
                              <Heading
                                size="headingxs"
                                as="h6"
                                className={`text-[16px] font-semibold ${location === item?.to ? "text-amber-700" : "text-white-a700 hover:text-amber-700"}`}
                              >
                                {item.name}
                              </Heading>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="flex w-[20%] justify-center rounded-[5px] border border-solid border-gray-300 md:w-full">
                      <div className="flex w-full items-center justify-center gap-3.5 rounded-[5px] border border-solid border-gray-300 p-3.5" style={{ cursor: 'pointer' }}>
                        <Heading
                          size="headingxs"
                          as="h6"
                          className="text-[16px] font-semibold capitalize text-white-a700 lg:text-[13px]"
                        >
                          Get Started
                        </Heading>
                        <Link to="/getstarted">
                          <Img src="images/img_heart.svg" alt="Favorites Icon" className="h-[16px] w-[16px]" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </header>
            </div>
            <div className="container-xs relative mt-[-174px] flex flex-col items-center px-14 lg:px-5 md:px-5">
              <div className="flex w-[88%] flex-col gap-4 rounded-[10px] bg-white-a700 px-[50px] py-[42px] shadow-xs lg:w-full md:w-full md:p-5 sm:p-4">
                <Heading
                  size="headingxl"
                  as="h1"
                  className="text-[27px] font-semibold leading-8 text-blue_gray-900 lg:text-[22px] md:text-[21px]"
                >
                  Get Started!
                </Heading>
                <form onSubmit={handleSubmit} className="container flex flex-col gap-6 p-8 shadow-lg rounded-lg bg-white">
                  <div className="flex flex-col gap-4">
                    {/* Row for Name and Email */}
                    <div className="flex flex-wrap gap-4">
                      {/* Name Field */}
                      <label className="flex-1 min-w-[200px]">
                        <Heading as="h6" className="text-[16px] font-medium text-blue_gray-900">First Name</Heading>
                        <Input
                          shape="round"
                          type="text"
                          name="firstName"
                          value={formData.firstName}
                          onChange={handleChange}
                          placeholder="Your First Name"
                          className="rounded-[5px] !border px-5 w-full"
                        />
                        {validationErrors.firstName && <span className="text-red-500">{validationErrors.firstName}</span>}
                      </label>
                      <label className="flex-1 min-w-[200px]">
                        <Heading as="h6" className="text-[16px] font-medium text-blue_gray-900">Last Name</Heading>
                        <Input
                          shape="round"
                          type="text"
                          name="lastName"
                          value={formData.lastName}
                          onChange={handleChange}
                          placeholder="Your Last Name"
                          className="rounded-[5px] !border px-5 w-full"
                        />
                        {validationErrors.lastName && <span className="text-red-500">{validationErrors.lastName}</span>}
                      </label>

                      {/* Email Field */}

                    </div>

                    {/* Row for Phone and Subject */}
                    <div className="flex flex-wrap gap-4">
                      <label className="flex-1 min-w-[200px]">
                        <Heading as="h6" className="text-[16px] font-medium text-blue_gray-900">Email</Heading>
                        <Input
                          shape="round"
                          type="email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          placeholder="Your Email"
                          className="rounded-[5px] !border px-5 w-full"
                        />
                        {validationErrors.email && <span className="text-red-500">{validationErrors.email}</span>}
                      </label>
                      {/* Phone Field */}
                      <label className="flex-1 min-w-[200px]">
                        <Heading as="h6" className="text-[16px] font-medium text-blue_gray-900">Phone</Heading>
                        <Input
                          shape="round"
                          type="tel"
                          name="phoneNumber"
                          value={formData.phoneNumber}
                          onChange={handleChange}
                          placeholder="Your Phone"
                          className="rounded-[5px] !border px-5 w-full"
                        />
                        {validationErrors.phoneNumber && <span className="text-red-500">{validationErrors.phoneNumber}</span>}
                      </label>
                    </div>

                    {/* Submit Button */}
                    <Button
                      shape="round"
                      className="rounded-[5px] px-6 font-semibold capitalize text-white bg-amber-700 hover:bg-amber-800"
                      type="submit"
                    >
                      Get Started
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
