import { Text, Heading } from "./..";
import React from "react";

interface Props {
  className?: string;
  stepNumber?: React.ReactNode;
  sectionHeading?: React.ReactNode;
  sectionDescription?: React.ReactNode;
}

export default function VolunteerProgramDescription({
  stepNumber = "01",
  sectionHeading = "Volunteering",
  sectionDescription = "We believe in the power of community involvement. Our volunteer programmes enable individuals to contribute to meaningful initiatives, supporting clients in need and making a positive impact in their lives.",
  ...props
}: Props) {
  return (
    <div {...props} className={`${props.className} flex sm:flex-col items-start gap-5 flex-1`}>
      <div className="flex h-[61px] w-[70px] flex-col items-center justify-center bg-[url(/public/images/img_1.svg)] bg-cover bg-no-repeat sm:h-auto">
        <Heading size="headingmd" as="h5" className="text-[20px] font-semibold text-white-a700 sm:text-[17px]">
          {stepNumber}
        </Heading>
      </div>
      <div className="flex flex-1 flex-col items-start self-center">
        <Heading size="headings" as="h6" className="text-[18px] font-semibold text-blue_gray-900 sm:text-[15px]">
          {sectionHeading}
        </Heading>
        <Text as="p" className="w-full text-[14px] font-normal leading-5 text-gray-600 sm:w-full">
          {sectionDescription}
        </Text>
      </div>
    </div>
  );
}
