import { Heading, Img } from "./..";
import React from "react";

interface Props {
  className?: string;
  mortarboardImage?: string;
  headingText?: React.ReactNode;
  descriptionText?: React.ReactNode;
}

export default function EducationInfoSection({
  mortarboardImage = "images/img_mortarboard.svg",
  headingText = "We Educate",
  descriptionText = "Empowering clients with essential knowledge about care options.",
  ...props
}: Props) {
  return (
    <div {...props} className={`${props.className} flex flex-col items-center gap-6`}>
      <div className="flex flex-col items-center rounded-[44px] bg-amber-700 p-5">
        <Img src={mortarboardImage} alt="Mortarboard Image" className="h-[50px] w-[50px]" />
      </div>
      <div className="flex flex-col items-center gap-1 self-stretch">
        <Heading size="headinglg" as="h5" className="text-[22px] font-semibold text-blue_gray-900">
          {headingText}
        </Heading>
        <Heading as="p" className="self-stretch text-center text-[16px] font-normal leading-[22px] text-gray-600">
          {descriptionText}
        </Heading>
      </div>
    </div>
  );
}
