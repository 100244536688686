import { useState } from "react";
import axios from "axios";
import { useSnackbar } from "notistack";
import { API_HEADER_KEY, API_URL } from "config";

interface FormData {
  name: string;
  email: string;
  phoneNumber: string;
  query: string;
  subject?: string;
}

interface ValidationErrors {
  name?: string;
  email?: string;
  phoneNumber?: string;
  query?: string;
  subject?: string;
}

export const useContact = () => {
  const [formData, setFormData] = useState<FormData>({
    name: "",
    email: "",
    phoneNumber: "",
    query: "",
    subject: "",
  });
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {

    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const validateFormData = () => {
    const errors: ValidationErrors = {};

    if (!formData.name.trim()) {
      errors.name = "Name is required.";
    }
    if (!formData.email.trim()) {
      errors.email = "Email is required.";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.email)) {
      errors.email = "Invalid email format.";
    }
    if (!formData.phoneNumber.trim()) {
      errors.phoneNumber = "Phone number is required.";
    } else if (!/^\+\d+$/.test(formData.phoneNumber)) {
      errors.phoneNumber = "Phone number must start with '+' and be numeric.";
    }
    if (!formData.query.trim()) {
      errors.query = "Message is required.";
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0; // Form is valid if no errors
  };


  const submitForm = async () => {
    if (!validateFormData()) {
      // enqueueSnackbar("Please fix validation errors.", { variant: "error" });
      return;
    }
    const {subject, ...rest} = formData;
    await axios?.post("/enquiries", rest, {
      headers: {
        "x-api-key": API_HEADER_KEY,
      },
      baseURL: API_URL,
    }).then((response) => {
      enqueueSnackbar("Message sent successfully!", { variant: "success" });
      setFormData({
        name: "",
        email: "",
        phoneNumber: "",
        query: "",
        subject: "",
      });
      setValidationErrors({});
    }).catch((error) => {
      enqueueSnackbar(error?.response?.data?.message ?? "Error submitting form. Please try again.", { variant: "error" });
      console.error("Error submitting form:", error);
    });
  };

  return { formData, handleChange, submitForm, validationErrors };
};