// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  padding: 0;
  font-family: Poppins;
}

* {
  box-sizing: border-box;
  line-height: normal;
  font-family: inherit;
  margin: unset;
}

a {
  text-decoration: none;
  display: block;
}

ul {
  margin: 0;
  margin-inline: unset !important;
  padding: 0;
  list-style: none;
}

input {
  background-color: transparent;
  width: 100%;
}

input:focus {
  outline: none;
}
`, "",{"version":3,"sources":["webpack://./src/styles/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,oBAAoB;AACtB;;AAEA;EACE,sBAAsB;EACtB,mBAAmB;EACnB,oBAAoB;EACpB,aAAa;AACf;;AAEA;EACE,qBAAqB;EACrB,cAAc;AAChB;;AAEA;EACE,SAAS;EACT,+BAA+B;EAC/B,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,6BAA6B;EAC7B,WAAW;AACb;;AAEA;EACE,aAAa;AACf","sourcesContent":["body {\n  margin: 0;\n  padding: 0;\n  font-family: Poppins;\n}\n\n* {\n  box-sizing: border-box;\n  line-height: normal;\n  font-family: inherit;\n  margin: unset;\n}\n\na {\n  text-decoration: none;\n  display: block;\n}\n\nul {\n  margin: 0;\n  margin-inline: unset !important;\n  padding: 0;\n  list-style: none;\n}\n\ninput {\n  background-color: transparent;\n  width: 100%;\n}\n\ninput:focus {\n  outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
