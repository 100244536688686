import React from "react";

const shapes = {
  round: "rounded-[5px]",
} as const;
const variants = {
  tarOutlineGray300: "!border-gray-300 border border-solid",
} as const;
const sizes = {
  xs: "h-[160px] p-5 text-[16px]",
} as const;

type TextAreaProps = Omit<
  React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>,
  "size" | "prefix" | "type" | "onChange"
> &
  Partial<{
    className: string;
    name: string;
    placeholder: string;
    onChange: any;
    shape: keyof typeof shapes;
    variant: keyof typeof variants | null;
    size: keyof typeof sizes;
  }>;

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      className = "",
      name = "",
      placeholder = "",
      shape,
      size = "xs",
      variant = "tarOutlineGray300",
      onChange,
      ...restProps
    },
    ref,
  ) => {

    return (
      <textarea
        ref={ref}
        className={`${className} ${shape && shapes[shape]} ${size && sizes[size]} ${variant && variants[variant]}`}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        {...restProps}
      />
    );
  },
);

export { TextArea };
