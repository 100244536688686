import { Text } from "../../components";
import React from "react";

export default function TermsAndConditionsSection() {
  return (
    <>
      {/* terms and conditions section */}
      <div className="flex justify-center">
        <div className="container-xs flex justify-center lg:px-5 md:px-5">
          <Text as="p" className="w-full text-[16px] font-normal leading-[22px] text-gray-600 lg:text-[13px]">
            <span className="text-[30px] font-semibold text-blue_gray-900">
              <>
                Terms and Conditions
                <br />
                <br />
              </>
            </span>
            <span className="font-bold text-blue_gray-900_01">
              <>
                Introduction
                <br />
              </>
            </span>
            <span className="text-gray-600">
              <>
                Welcome to EXACT CARE (&quot;we,&quot; &quot;us,&quot; &quot;our&quot;). This website is
                provided as an information and service platform for EXACT CARE, specialising in home care
                assistance, medical support, and related services. By accessing or using this site, you agree to follow
                and be bound by these Terms.
                <br />
                <br />
              </>
            </span>
            <span className="font-bold text-blue_gray-900_01">
              <>
                Services Provided
                <br />
              </>
            </span>
            <span className="text-gray-600">
              <>
              EXACT CARE offers a variety of in-home services, including personal care assistance, medical
                support, companionship, and household help. Please note that while we aim to deliver high-quality,
                personalised care, our services have specific boundaries. For instance, we do not offer emergency
                medical services or prescribe medications. For a comprehensive list of services and applicable
                conditions or limitations, please contact us.
                <br />
                <br />
              </>
            </span>
            <span className="font-bold text-blue_gray-900_01">
              <>
                User Responsibilities
                <br />
              </>
            </span>
            <span className="text-gray-600">
              <>
                By using this website or our services, you agree to:
                <br />
                Provide accurate, up-to-date, and complete information as requested during service inquiries or
                registration.
                <br />
                Adhere to any age restrictions; services are available to individuals 18 years and older or with
                parental/guardian consent if applicable.
                <br />
                Recognise and respect any limitations of our services as stated.
                <br />
                <br />
              </>
            </span>
            <span className="font-bold text-blue_gray-900_01">
              <>
                Payment Terms
                <br />
              </>
            </span>
            <span className="text-gray-600">
              <>
                Pricing, payment options, and billing cycles for our services are detailed in your service agreement.
                Payments must be made according to the chosen service plan. We accept [payment methods, e.g., credit
                cards, bank transfers]. For service cancellations, please refer to our refund and cancellation policy.
                Refunds are processed as per applicable laws and the terms outlined in your service agreement.
                <br />
              </>
            </span>
            <span className="font-bold text-gray-600">
              <>
                <br />
              </>
            </span>
            <span className="font-bold text-blue_gray-900_01">
              <>
                Intellectual Property
                <br />
              </>
            </span>
            <span className="text-gray-600">
              <>
                &nbsp;All content on this website—including text, graphics, logos, images, and software—is owned by
                EXACT CARE and protected under copyright and other intellectual property laws. Reproduction,
                distribution, modification, or republication of our content without written permission is strictly
                prohibited.
                <br />
              </>
            </span>
            <span className="font-bold text-blue_gray-900_01">
              <>
                <br />
                Limitation of Liability
                <br />
              </>
            </span>
            <span className="text-gray-600">
              <>
              EXACT CARE shall not be held liable for any direct or indirect damages resulting from the use
                or inability to use our services, including issues related to third-party links or content on our
                website. Users acknowledge that while we endeavour to provide reliable and secure services, all
                interactions are undertaken at their own risk.
                <br />
              </>
            </span>
            <span className="font-bold text-gray-600">
              <>
                <br />
              </>
            </span>
            <span className="font-bold text-blue_gray-900_01">
              <>
                Termination
                <br />
              </>
            </span>
            <span className="text-gray-600">
              <>
                &nbsp;We may update this Privacy Policy to reflect changes in our practices or applicable legal
                requirements. Updates will be posted on this page, and significant changes will be communicated directly
                to users when required.
                <br />
              </>
            </span>
            <span className="font-bold text-blue_gray-900_01">
              <>
                <br />
                Governing Law
                <br />
              </>
            </span>
            <span className="text-gray-600">
              These Terms and Conditions are governed by and interpreted in accordance with the laws of England and
              Wales. By using this website, you agree that any disputes will fall under the jurisdiction of the courts
              of England and Wales.
            </span>
          </Text>
        </div>
      </div>
    </>
  );
}
