import { Text, Heading, Img } from "../../components";
import React from "react";

export default function AboutUsSection2() {
  return (
    <>
      {/* about us section */}
      <div className="flex justify-center">
        <div className="container-xs flex items-start justify-center lg:px-5 md:flex-col md:px-5">
          <div className="relative h-[580px] flex-1 self-center md:w-full md:flex-none md:self-stretch">
            <Img
              src="images/img_img_340x300.png"
              alt="Primary Image"
              className="absolute left-0 top-0 m-auto h-[340px] w-[42%] rounded-[10px] object-contain"
            />
            <div className="absolute bottom-0 left-0 right-0 m-auto flex flex-1 items-center justify-center px-14 md:px-5 sm:relative sm:flex-col sm:px-4">
              <div className="relative z-[1] h-[170px] w-[170px] rounded-[84px] bg-amber-700" />
              <Img
                src="images/img_img_480x410.png"
                alt="Secondary Image"
                className="relative ml-[-84px] h-[480px] w-[62%] rounded-[10px] object-contain sm:ml-0 sm:w-full"
              />
            </div>
          </div>
          <div className="mt-[94px] flex w-[46%] flex-col items-start md:w-full">
            <Heading
              size="heading3xl"
              as="h2"
              className="text-[40px] font-semibold leading-[46px] text-blue_gray-900 lg:text-[34px] md:text-[34px] sm:text-[32px]"
            >
              <>
                Elderly Empowerment
                <br />
                Care
              </>
            </Heading>
            <div className="mt-3.5 h-[3px] w-[10%] bg-amber-700" />
            <Heading
              as="h3"
              className="mt-3.5 w-full text-[16px] font-normal leading-[22px] text-gray-600 lg:text-[13px]"
            >
              We provides a compassionate and supportive environment for individuals in need. Our dedicated team ensures
              that each resident receives personalised care, fostering a sense of community and belonging.
            </Heading>
            <div className="mt-[26px] flex flex-col gap-4 self-stretch">
              <div>
                <div className="flex flex-col gap-1.5">
                  <div className="flex flex-wrap justify-between gap-5">
                    <Heading
                      size="headings"
                      as="h4"
                      className="text-[18px] font-semibold text-blue_gray-900 lg:text-[15px]"
                    >
                      Completed Care Plans
                    </Heading>
                    <Text size="textmd" as="p" className="text-[18px] font-normal text-blue_gray-900 lg:text-[15px]">
                      65%
                    </Text>
                  </div>
                  <div className="flex rounded bg-gray-100">
                    <div className="h-[8px] w-[62%] rounded bg-teal-600" />
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <div className="flex flex-wrap justify-between gap-5">
                  <Heading
                    size="headings"
                    as="h5"
                    className="text-[18px] font-semibold text-blue_gray-900 lg:text-[15px]"
                  >
                    New Admissions This Month
                  </Heading>
                  <Text size="textmd" as="p" className="text-[18px] font-normal text-blue_gray-900 lg:text-[15px]">
                    80%
                  </Text>
                </div>
                <div className="relative h-[8px] rounded bg-gray-100">
                  <div style={{ width: "82%" }} className="absolute h-full rounded bg-teal-600" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
