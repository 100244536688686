import { Link, useLocation } from "react-router-dom";
import { Button, Img, Heading } from "./..";
import React from "react";

interface Props {
  className?: string;
}

const navItems = [
  { name: "Home", to: "/", textClass: "text-amber-700" },
  { name: "About Us", to: "/about", textClass: "text-white-a700 hover:text-amber-700" },
  { name: "Services", to: "/services", textClass: "text-white-a700 hover:text-amber-700" },
  { name: "Contact Us", to: "/contacts", textClass: "text-white-a700 hover:text-amber-700" },
];

export default function Header({ ...props }: Props) {
  const location = useLocation()?.pathname;
  console.log('location', location);

  return (
    <header {...props} className={`${props.className} flex justify-between items-center gap-5`}>
      <Img src="images/img_header_logo.svg" alt="Header Logo" className="h-[40px] w-[186px] object-contain" />
      <div className="flex">
        <ul className="flex flex-wrap gap-[90px] md:gap-5">
          {navItems.map((item, index) => (
            <li key={index}>
              <Link to={item.to} className="cursor-pointer">
                <Heading
                  size="headingxs"
                  as="h6"
                  className={`text-[16px] font-semibold ${location === item?.to ? "text-[#ECA30C]" : "text-white-a700 hover:text-amber-700"}`}
                >
                  {item.name}
                </Heading>
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <Link to="/getstarted">
        <Button
          // color={location === '' ? "text-amber-700" : "text-white-a700 hover:text-amber-700"}
          color="text-white-a700 hover:text-amber-700"
          variant="outline"
          shape="round"
          rightIcon={<Img src="images/img_heart.svg" alt="Heart" className="my-1 h-[16px] w-[16px]" />}
          className="min-w-[192px] gap-3.5 rounded-[5px] !border px-[34px] text-white-a700 font-semibold capitalize sm:px-5"
        >
          Get Started
        </Button>
      </Link>
    </header>
  );
}