import { Link } from "react-router-dom";
import { Button, Img, Heading } from "../../components";
import React from "react";

export default function AboutUsSection() {
  return (
    <>
      {/* about us section */}
      <div className="relative h-[610px] px-40 lg:px-8 md:px-5 sm:px-4">
        <Img
          src="images/img_element_1.svg"
          alt="Main Image"
          className="absolute left-[8%] top-0 m-auto h-[370px] w-[370px]"
        />
        <div className="absolute bottom-0 left-0 right-0 m-auto flex w-[92%] items-center justify-end lg:gap-[80px] md:gap-[30px] md:relative md:flex-col">
          <Img
            src="images/img_img_560x520.png"
            alt="Main Image"
            className="h-[560px] w-[36%] rounded-[10px] object-contain md:w-full"
          />
          <div className="mb-[66px] flex w-[50%] flex-col items-start self-end md:mb-0 md:w-full md:self-auto">
            <Heading
              size="heading3xl"
              as="h2"
              className="text-[40px] font-semibold leading-[46px] text-blue_gray-900 lg:text-[34px] md:text-[34px] sm:text-[32px]"
            >
              <>
                Empowering Lives Today
                <br />
                Supporting Futures Tomorrow
              </>
            </Heading>
            <div className="mt-3.5 h-[3px] w-[8%] bg-amber-700" />
            <Heading
              as="h3"
              className="mt-3.5 w-full text-[16px] font-normal leading-[22px] text-gray-600 lg:text-[13px]"
            >
              We are dedicated to providing exceptional care and comprehensive case management services to those facing
              complex challenges. Our team is committed to ensuring that every client receives personalised support
              tailored to their unique needs. By collaborating with healthcare providers and stakeholders, we aim to
              enhance the quality of life for our clients, ensuring they live with dignity and independence.
            </Heading>
            <div className="mt-[18px] flex items-center gap-5 self-stretch md:flex-col">
              <Img src="images/img_dart_board_1.svg" alt="Purpose Image" className="h-[50px] w-[50px] md:w-full" />
              <Heading
                size="headings"
                as="h4"
                className="mb-2 self-end text-[18px] font-semibold text-blue_gray-900 lg:text-[15px] md:mb-0 md:self-auto"
              >
                Our Sole Purpose is to Enhance the Lives of Those We Serve.
              </Heading>
            </div>
            <Link to="/getstarted">
              <Button
                shape="round"
                rightIcon={
                  <Img
                    src="images/img_heart_white_a700.png"
                    alt="Heart"
                    className="my-1 h-[16px] w-[10px] object-cover"
                  />
                }
                className="mt-[30px] min-w-[196px] gap-2 rounded-[5px] px-[34px] font-semibold capitalize sm:px-4"
              >
                Get Started
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
