import React from "react";
import { useRoutes } from "react-router-dom";
import Home from "pages/Home";
import NotFound from "pages/NotFound";
import Home1 from "pages/Home1";
import About from "pages/About";
import Services from "pages/Services";
import Ontacts from "pages/Contacts";
import GetStarted from "pages/GetStarted";
import PrivacyPolicy from "pages/PrivacyPolicy";
import TermsandConditions from "pages/TermsandConditions";

const ProjectRoutes = () => {
  let element = useRoutes([
    { path: "/", element: <Home1 /> },
    { path: "*", element: <NotFound /> },
    // {
    //   path: "/",
    //   element: <Home1 />,
    // },
    {
      path: "about",
      element: <About />,
    },
    {
      path: "services",
      element: <Services />,
    },
    {
      path: "contacts",
      element: <Ontacts />,
    },
    {
      path: "getstarted",
      element: <GetStarted />,
    },
    {
      path: "privacypolicy",
      element: <PrivacyPolicy />,
    },
    {
      path: "termsandconditions",
      element: <TermsandConditions />,
    },
  ]);

  return element;
};

export default ProjectRoutes;
