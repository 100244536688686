import { Heading } from "../../components";
import ClientImprovementStats from "../../components/ClientImprovementStats";
import React from "react";

export default function AboutQuickInsightsSection() {
  return (
    <>
      {/* about quick insights section */}
      <div className="flex flex-col items-center">
        <div className="container-xs flex flex-col gap-[30px] lg:px-5 md:px-5">
          <div className="mx-[294px] flex flex-col items-center gap-1 md:mx-0">
            <Heading size="headingmd" as="h2" className="text-[20px] font-semibold text-teal-600 lg:text-[17px]">
              Quick Insights
            </Heading>
            <Heading
              size="heading3xl"
              as="h3"
              className="self-stretch text-center text-[40px] font-semibold leading-[46px] text-blue_gray-900 lg:text-[34px] md:text-[34px] sm:text-[32px]"
            >
              Key Facts About Us
            </Heading>
          </div>
          <div className="flex flex-col items-center gap-10">
            <div className="h-[3px] w-[5%] bg-amber-700" />
            <div className="flex gap-[30px] self-stretch md:flex-col">
              <ClientImprovementStats className="w-[26%] md:w-full" />
              <ClientImprovementStats
                percentageText="90%"
                ellipseImage="images/img_ellipse_teal_600.svg"
                descriptionText="90% of our care plans are customised to meet individual needs."
                className="mb-[26px] w-[26%] md:mb-0 md:w-full"
              />
              <ClientImprovementStats
                percentageText="85%"
                ellipseImage="images/img_ellipse_teal_600.svg"
                descriptionText="85% of our clients express satisfaction with our support."
                className="mb-[26px] w-[26%] md:mb-0 md:w-full"
              />
              <ClientImprovementStats
                descriptionText="60% of our partnerships enhance our service delivery."
                className="mb-[26px] w-[26%] md:mb-0 md:w-full"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
