import React from "react";

const shapes = {
  round: "rounded-[5px]",
} as const;

const variants = {
  outline: {
    gray_300: "border-gray-300 border border-solid text-gray-600",
  },
} as const;

const sizes = {
  xs: "h-[58px] px-5 text-[16px]",
} as const;

type InputProps = Omit<React.ComponentPropsWithoutRef<"input">, "prefix" | "size"> &
  Partial<{
    label: string;
    prefix: React.ReactNode;
    suffix: React.ReactNode;
    shape: keyof typeof shapes;
    variant: keyof typeof variants | null;
    size: keyof typeof sizes;
    color: string;
  }>;

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className = "",
      name = "",
      placeholder = "",
      type = "text",
      label = "",
      prefix,
      suffix,
      onChange,
      shape,
      variant = "outline",
      size = "xs",
      color = "gray_300",
      ...restProps
    },
    ref,
  ) => {
    return (
      <input className="border-gray-300 border border-solid rounded-[5px] " ref={ref} type={type} name={name} placeholder={placeholder} onChange={onChange} {...restProps} />
    );
  },
);

export { Input };
