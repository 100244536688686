import { Heading, Img } from "./..";
import React from "react";

interface Props {
  className?: string;
  percentageText?: React.ReactNode;
  ellipseImage?: string;
  descriptionText?: React.ReactNode;
}

export default function ClientImprovementStats({
  percentageText = "75%",
  ellipseImage = "images/img_ellipse.svg",
  descriptionText = "75% of clients report improved quality of life after receiving our services.",
  ...props
}: Props) {
  return (
    <div {...props} className={`${props.className} flex flex-col items-center gap-[30px]`}>
      <div className="relative mx-[54px] h-[190px] content-center self-stretch">
        <Heading
          size="heading3xl"
          as="h1"
          className="mx-auto flex-1 text-center text-[40px] font-semibold leading-[46px] text-blue_gray-900"
        >
          {percentageText}
        </Heading>
        <Img
          src={ellipseImage}
          alt="Decorative Image"
          className="absolute bottom-0 left-0 right-0 top-0 m-auto h-[190px] w-full"
        />
      </div>
      <Heading
        size="headingmd"
        as="h5"
        className="self-stretch text-center text-[20px] font-semibold leading-[26px] text-blue_gray-900"
      >
        {descriptionText}
      </Heading>
    </div>
  );
}
