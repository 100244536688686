import { Heading, Button, Img } from "./..";
import React from "react";

interface Props {
  className?: string;
  callUsText?: React.ReactNode;
  phoneNumberPlaceholder?: React.ReactNode;
  imgPath?: string;
}

export default function ContactUsSection({
  callUsText = "Call Us",
  phoneNumberPlaceholder = `${process.env.REACT_APP_PUBLIC_CONTACT_NO}`,
  imgPath,
  ...props
}: Props) {
  return (
    <div {...props} className={`${props.className} flex justify-center items-center gap-5 flex-1`}>
      <Button size="sm" className="w-[70px] rounded-[34px] px-5">
        <Img src={imgPath} />
      </Button>
      <div className="flex flex-1 flex-col items-start">
        <Heading size="headingmd" as="h5" className="text-[20px] font-semibold text-blue_gray-900">
          {callUsText}
        </Heading>
        <Heading as="p" className="text-[16px] font-normal text-gray-600">
          {phoneNumberPlaceholder}
        </Heading>
      </div>
    </div>
  );
}
