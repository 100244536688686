import { Img, Text, Heading } from "../../components";
import React from "react";

export default function OurTeamSection() {
  return (
    <>
      {/* our team section */}
    
      <div className="relative mt-[-20px] h-[774px] lg:h-auto md:h-auto sm:mt-[150px]">
        <Img
          src="images/img_element_1.svg"
          alt="Featured Image"
          className="mr-[194px] mt-[120px] h-[370px] w-[370px] lg:mr-0 md:mr-0"
        />
       
        <div className="absolute bottom-0 left-0 right-0 top-0 m-auto h-[774px] flex-1">
        
          <div className="absolute left-0 right-0 top-0 m-auto flex h-[636px] flex-1 items-end justify-center bg-[url(/public/images/img_group_20.svg)] bg-cover bg-no-repeat py-[18px] ">
            <div className="container-xs mt-[212px] flex justify-center lg:px-5 md:px-5">
              <div className="flex w-full flex-col items-start">
                <Heading size="headingmd" as="h4" className="text-[20px] font-semibold text-teal-600 lg:text-[17px]">
                  Our Team
                </Heading>
                <Heading
                  size="heading3xl"
                  as="h5"
                  className="mt-1.5 w-[48%] text-[40px] font-semibold leading-[46px] text-blue_gray-900 lg:w-full lg:text-[34px] md:w-full md:text-[34px] sm:text-[32px]"
                >
                  Together, We Create a Better Future
                </Heading>
                <Heading
                  as="h6"
                  className="mt-8 w-[48%] text-[16px] font-normal leading-[22px] text-gray-600 lg:w-full lg:text-[13px] md:w-full"
                >
                  We are a dedicated group of professionals committed to delivering high-quality care and support to our
                  clients. With a focus on compassion and expertise, we work collaboratively to ensure that every
                  individual receives personalised attention and the best possible outcomes.
                </Heading>
                <div className="mt-[30px] flex w-[48%] items-start justify-center gap-5 rounded-[10px] bg-gray-100 p-5 lg:w-full md:w-full sm:flex-col">
                  <Img src="images/img_quote_left_1.svg" alt="Quote Icon" className="h-[20px] w-[20px] sm:w-full" />
                  <Text
                    as="p"
                    className="w-[96%] self-center text-[16px] font-medium italic leading-[22px] text-blue_gray-900 lg:text-[13px] sm:w-full"
                  >
                    Compassionate care is not just about meeting needs; it’s about empowering individuals to live their
                    lives to the fullest with dignity and respect.
                  </Text>
                </div>
              </div>
            </div>
          </div>
          <Img
            src="images/img_img_600x600.png"
            alt="Product Image"
            className="absolute bottom-[-1px] right-[5%] lg:right-[5%] m-auto h-[600px] w-[600px] rounded-[300px] object-cover lg:bottom-0 lg:right-0 lg:w-[700px] lg:h-[300px] md:w-[300px] md:h-[300px] sm:relative sm:bottom-auto sm:right-auto sm:mt-5 sm:w-[250px] sm:h-[250px] lg:mb-[400px] md:hidden"
          />
        </div>
      </div>
    </>
  );
}
