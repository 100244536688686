import { useState } from "react";
import axios from "axios";
import { useSnackbar } from "notistack";
import { API_HEADER_KEY, API_URL } from "config";

interface FormData {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
}

interface ValidationErrors {
    firstName?: string;
    lastName?: string;
    email?: string;
    phoneNumber?: string;
}

export const useGetStarted = () => {
    const [formData, setFormData] = useState<FormData>({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
    });
    const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});

    const { enqueueSnackbar } = useSnackbar();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | { name?: string; value: string }>) => {
        console.log(e.target);

        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",
        }));
    };

    const validateFormData = () => {
        const errors: ValidationErrors = {};

        if (!formData.firstName.trim()) {
            errors.firstName = "First name is required.";
        }
        if (!formData.lastName.trim()) {
            errors.lastName = "Last name is required.";
        }
        if (!formData.email.trim()) {
            errors.email = "Email is required.";
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.email)) {
            errors.email = "Invalid email format.";
        }
        if (!formData.phoneNumber.trim()) {
            errors.phoneNumber = "Phone number is required.";
        } else if (!/^\+\d+$/.test(formData.phoneNumber)) {
            errors.phoneNumber = "Phone number must start with '+' and be numeric.";
        }

        setValidationErrors(errors);
        return Object.keys(errors).length === 0; // Form is valid if no errors
    };


    const submitForm = async () => {
        if (!validateFormData()) {
            // enqueueSnackbar("Please fix validation errors.", { variant: "error" });
            return;
        }
        await axios?.post("/signup-leads", formData, {
            headers: {
                "x-api-key": API_HEADER_KEY,
            },
            baseURL: API_URL,
        }).then((response) => {
            setFormData({
                firstName: "",
                lastName: "",
                email: "",
                phoneNumber: "",
            });
            setValidationErrors({});
            enqueueSnackbar("Created successfully!", { variant: "success" });
        }).catch((error) => {
            enqueueSnackbar(error?.response?.data?.message ?? "Error submitting form. Please try again.", { variant: "error" });
            console.error("Error submitting form:", error);
        });
    };

    return { formData, handleChange, submitForm, validationErrors };
};