import { Link } from "react-router-dom";
import { Button, Heading, Img } from "../../components";
import React from "react";

export default function AboutUsSection() {
  return (
    <>
      {/* about us section */}
      <div className="mt-[222px] flex justify-center px-14 md:px-5 sm:px-4">
        <div className="flex w-[76%] items-start justify-center lg:w-full md:w-full md:flex-col">
          <div className="relative h-[610px] flex-1 self-center md:w-full md:flex-none md:self-stretch">
            <div className="absolute left-0 right-0 top-0 m-auto flex flex-1 items-start sm:relative sm:flex-col">
              <div className="relative z-[1] flex flex-col items-center rounded-[44px] bg-amber-700 p-5 sm:hidden">
                <Img src="images/img_solidarity.svg" alt="Solidarity Image" className="h-[50px] w-[50px] " />
              </div>
              <Img
                src="images/img_img.png"
                alt="Secondary Image"
                className="relative ml-[-44px] h-[560px] w-[66%] self-center rounded-[10px] object-contain sm:ml-0 sm:w-full"
              />
            </div>
            <Img
              src="images/img_img_320x270.png"
              alt="Feature Image"
              className="absolute bottom-0 right-[12%] m-auto h-[320px] w-[42%] rounded-[10px] object-contain sm:hidden"
            />
          </div>
          <div className="flex w-[46%] flex-col items-start md:w-full">
            <Heading size="headingmd" as="h2" className="text-[20px] font-semibold text-teal-600 lg:text-[17px]">
              About Us
            </Heading>
            <Heading
              size="heading3xl"
              as="h3"
              className="mt-1.5 w-[92%] text-[40px] font-semibold leading-[46px] text-blue_gray-900 lg:w-full lg:text-[34px] md:w-full md:text-[34px] sm:text-[32px]"
            >
              Complex Care and Case Management
            </Heading>
            <div className="mt-3.5 h-[3px] w-[8%] bg-amber-700" />
            <Heading
              as="h4"
              className="mt-3.5 w-[92%] text-[16px] font-normal leading-[22px] text-gray-600 lg:w-full lg:text-[13px] md:w-full"
            >
              We are a dedicated consultancy specialising in providing personalised care solutions and comprehensive
              case management services. Our mission is to empower individuals facing complex challenges by delivering
              tailored support that enhances their quality of life.
            </Heading>
            <div className="mt-1.5 flex flex-col gap-1 self-stretch">
              <div className="flex items-start gap-2.5">
                <Img src="images/img_checkmark.svg" alt="Checkmark Icon" className="mt-1 h-[18px] w-[18px]" />
                <Heading
                  size="headings"
                  as="h5"
                  className="self-center text-[18px] font-semibold text-blue_gray-900 lg:text-[15px]"
                >
                  Support Individuals in Need
                </Heading>
              </div>
              <div className="flex items-start gap-2.5">
                <Img src="images/img_checkmark.svg" alt="Checkmark Icon" className="mt-1 h-[18px] w-[18px]" />
                <Heading
                  size="headings"
                  as="h6"
                  className="self-center text-[18px] font-semibold text-blue_gray-900 lg:text-[15px]"
                >
                  Expertise in Care Provision
                </Heading>
              </div>
              <div className="flex items-start gap-2.5">
                <Img src="images/img_checkmark.svg" alt="Checkmark Icon" className="mt-1 h-[18px] w-[18px]" />
                <Heading
                  size="headings"
                  as="h6"
                  className="self-center text-[18px] font-semibold text-blue_gray-900 lg:text-[15px]"
                >
                  Enhancing Lives
                </Heading>
              </div>
              <div className="flex items-center gap-2.5">
                <Img src="images/img_checkmark.svg" alt="Checkmark Icon" className="mb-1 h-[18px] w-[18px] self-end" />
                <Heading
                  size="headings"
                  as="h6"
                  className="text-[18px] font-semibold text-blue_gray-900 lg:text-[15px]"
                >
                  Join Us in Our Mission
                </Heading>
              </div>
            </div>
            <Link to="/about">
              <Button
                shape="round"
                className="mt-[38px] min-w-[142px] rounded-[5px] px-[34px] font-semibold capitalize sm:px-4"
              >
                About us
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
