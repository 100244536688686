import { Heading } from "../../components";
import EducationInfoSection from "../../components/EducationInfoSection";
import React, { Suspense } from "react";

const educationList = [
  {
    mortarboardImage: "images/img_mortarboard.svg",
    headingText: "We Educate",
    descriptionText: "Empowering clients with essential knowledge about care options.",
  },
  {
    mortarboardImage: "images/img_solidarity.svg",
    headingText: "We Support",
    descriptionText: "Providing assistance for individual needs.",
  },
  {
    mortarboardImage: "images/img_mortarboard.svg",
    headingText: "We Educate",
    descriptionText: "Empowering clients with essential knowledge about care options.",
  },
  {
    mortarboardImage: "images/img_mortarboard.svg",
    headingText: "We Educate",
    descriptionText: "Empowering clients with essential knowledge about care options.",
  },
];

export default function WhatMakesUsSection() {
  return (
    <>
      {/* what makes us section */}
      <div className="mt-[148px]">
        <div className="bg-gray-100">
          <div className="flex h-[646px] items-center justify-center bg-[url(/public/images/img_group_22.svg)] bg-cover bg-no-repeat py-[118px] lg:h-auto lg:py-8 md:h-auto md:py-5 sm:py-4">
            <div className="container-xs mb-2 flex justify-center px-7 lg:px-5 md:px-5">
              <div className="flex w-full flex-col items-center">
                <Heading size="headingmd" as="h2" className="text-[20px] font-semibold text-teal-600 lg:text-[17px]">
                  Help is Our Goal
                </Heading>
                <Heading
                  size="heading3xl"
                  as="h3"
                  className="mt-1 w-[56%] text-center text-[40px] font-semibold leading-[46px] text-blue_gray-900 lg:w-full lg:text-[34px] md:w-full md:text-[34px] sm:text-[32px]"
                >
                  What Make Us Different
                </Heading>
                <div className="mt-[92px] flex gap-[90px] self-stretch md:flex-col">
                  <Suspense fallback={<div>Loading feed...</div>}>
                    {educationList.map((d, index) => (
                      <EducationInfoSection {...d} key={"servicesList" + index} className="w-[24%] md:w-full" />
                    ))}
                  </Suspense>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
