import { Img, Heading, BreadcrumbLink, Breadcrumb, BreadcrumbItem } from "../../components";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import ServicesSection from "./ServicesSection";
import React from "react";
import { Link } from "react-router-dom";

export default function ServicesPage() {
  return (
    <>
      <div className="flex w-full flex-col gap-[120px] bg-white-a700 lg:gap-[90px] md:gap-[90px] sm:gap-[60px]">
        <div className="h-[462px] bg-[url(/public/images/img_breadcrumbs_462x1920.png)] bg-cover bg-no-repeat lg:h-auto md:h-auto">
          <div className="flex flex-col items-center bg-blue_gray-900_b2 py-[18px]">
            <div className="container-xs mt-3.5 flex flex-col items-start lg:px-5 md:px-5">
              <Header className="self-stretch sm:flex-col" />
              <Heading
                size="heading3xl"
                as="h1"
                className="mt-[146px] w-[74%] text-[40px] font-semibold leading-[46px] text-white-a700 lg:w-full lg:text-[34px] md:w-full md:text-[34px] sm:text-[32px]"
              >
                Services
              </Heading>
              <div className="mt-3.5 h-[3px] w-[5%] rounded-[1px] bg-amber-700" />
              <Breadcrumb
                separator={<Img src="images/img_crumbs.svg" alt="Arrow Right" className="h-[10px] w-[10px]" />}
                className="mt-[118px] flex flex-wrap items-center gap-2.5 self-stretch"
              >
                <BreadcrumbItem>
                  <Link to="/" className="lg:text-[15px]">
                    <Heading size="headings" as="h2" className="text-[18px] font-semibold text-white-a700" style={{ color: '#ECA30C' }}>
                      Home
                    </Heading>
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem isCurrentPage>
                  <BreadcrumbLink className="lg:text-[15px]">
                    <Heading size="headings" as="h3" className="text-[18px] font-semibold text-white-a700">
                      Services
                    </Heading>
                  </BreadcrumbLink>
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
          </div>
        </div>

        {/* services section */}
        <ServicesSection />

        {/* family support and education section */}
        {/* <FamilySupportAndEducationSection /> */}
        <Footer />
      </div>
    </>
  );
}
