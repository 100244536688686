import { Img, Button, Heading, Text } from "../../components";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import VolunteerProgramDescription from "../../components/VolunteerProgramDescription";
import AboutUsSection from "./AboutUsSection";
import OurTeamSection from "./OurTeamSection";
import WhatMakesUsSection from "./WhatMakesUsSection";
import React, { Suspense } from "react";
import { Link } from "react-router-dom";

const volunteerProgramsList = [
  {
    stepNumber: "01",
    sectionHeading: "Volunteering",
    sectionDescription:
      "We believe in the power of community involvement. Our volunteer programmes enable individuals to contribute to meaningful initiatives, supporting clients in need and making a positive impact in their lives.",
  },
  {
    stepNumber: "02",
    sectionHeading: "Care Solutions",
    sectionDescription:
      "Our mission is to provide personalised care packages that address the unique needs of our clients. We strive to enhance their quality of life through comprehensive support and dedicated case management.",
  },
  {
    stepNumber: "03",
    sectionHeading: "Emergency Support",
    sectionDescription:
      "We are committed to delivering timely assistance to individuals facing urgent care needs. Our team is equipped to respond effectively, ensuring that no one is left without essential support in critical situations.",
  },
];

export default function Home1Page() {
  return (
    <>
      <div className="w-full bg-white-a700">
        <div>
          <div className="h-[1080px] bg-[url(/public/images/img_group_313.png)] bg-cover bg-no-repeat lg:h-auto md:h-auto">
            <div className="flex flex-col items-center bg-blue_gray-900_b2 py-[27px] sm:py-4">
              <div className="container-xs mb-[300px] flex flex-col gap-[354px] lg:gap-[265px] lg:px-5 md:gap-[265px] md:px-5 sm:gap-[177px]">
                <Header className="sm:flex-col" />
                <div>
                  <div className="flex flex-col gap-[30px]">
                    <Heading
                      size="heading4xl"
                      as="h1"
                      className="w-[50%] text-[54px] font-bold leading-[60px] text-white-a700 lg:w-full lg:text-[45px] md:w-full md:text-[34px] sm:text-[28px]"
                    >
                      Your Partner in Complex Care and Case Management
                    </Heading>
                    <div className="flex items-center gap-5 md:flex-col">
                      <div className="h-[98px] w-[3px] rounded-[1px] bg-amber-700 md:h-[3px] md:w-[98px]" />
                      <Text
                        size="textmd"
                        as="p"
                        className="w-[50%] text-[18px] font-normal leading-[26px] text-white-a700 lg:w-[50%] lg:text-[15px] md:w-full"
                      >
                        Empower you to navigate your journey with confidence and achieve the highest quality of
                        life.Together, we’ll ensure that every step of your care pathway is managed with expertise,
                        compassion, and unwavering commitment.
                      </Text>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* about us section */}
          <AboutUsSection />

          {/* what makes us section */}
          <WhatMakesUsSection />
          <div className="mx-[92px] mt-[258px] flex items-center justify-end gap-[30px] md:mx-0 md:flex-col">
            <div className="mt-[60px] flex w-[24%] flex-col items-start self-start md:mt-0 md:w-full md:self-auto md:px-5">
              <Heading size="headingmd" as="h2" className="text-[20px] font-semibold text-teal-600 lg:text-[17px]">
                Our Mission & Goals
              </Heading>
              <Heading
                size="heading3xl"
                as="h3"
                className="mt-1.5 w-full text-[40px] font-semibold leading-[46px] text-blue_gray-900 lg:text-[34px] md:text-[34px] sm:text-[32px]"
              >
                Empowering Lives, Enhancing Care
              </Heading>
              <div className="mt-3.5 h-[3px] w-[14%] bg-amber-700" />
              <Heading
                as="h4"
                className="mt-3.5 w-full text-[16px] font-normal leading-[22px] text-gray-600 lg:text-[13px]"
              >
                We are dedicated to providing personalised support that meets the unique needs of each individual.
                Through our comprehensive services, we strive to improve the quality of life for those facing complex
                health challenges and ensure they receive the care they deserve.
              </Heading>
            </div>
            <Img
              src="images/img_img_500x410.png"
              alt="Mission Image"
              className="h-[500px] w-[24%] rounded-[10px] object-contain md:w-full"
            />
            <div className="flex w-[38%] flex-col gap-1.5 md:w-full md:px-5">
              <Suspense fallback={<div>Loading feed...</div>}>
                {volunteerProgramsList.map((d, index) => (
                  <VolunteerProgramDescription {...d} key={"featuresList" + index} />
                ))}
              </Suspense>
            </div>
          </div>
          <div className="mt-[200px]">
            <div className="relative z-[2] flex flex-col items-start">
              <div className="flex flex-col items-center self-stretch">
                <div className="container-xs relative z-[3] flex flex-col items-start pl-[334px] pr-14 lg:px-5 md:px-5">
                  <div className="flex flex-col items-center rounded-[44px] bg-amber-700 p-5 shadow-xs lg:mb-[50px]">
                    <Img src="images/img_globe.svg" alt="Globe Image" className="h-[50px] w-[50px]" />
                  </div>
                </div>
                <div className="relative mt-[-70px] flex justify-center self-stretch bg-blue_gray-900_b2 py-[114px] lg:py-8 md:py-5 sm:py-4">
                  <div className="container-xs flex justify-center lg:px-5 md:px-5">
                    <div className="flex w-full flex-col items-start gap-2.5">
                      <Heading
                        size="headingmd"
                        as="h2"
                        className="text-[20px] font-semibold text-teal-600 lg:text-[17px]" 
                      >
                        Client-Centric Care
                      </Heading>
                      <div className="flex w-[94%] items-start lg:w-full md:w-full md:flex-col">
                        <Heading
                          size="heading3xl"
                          as="h3"
                          className="w-[50%] self-center text-[40px] font-semibold leading-[46px] text-white-a700 lg:w-[50%] lg:text-[34px] md:w-full md:text-[34px] sm:text-[32px]"
                        >
                          Join Our Mission to Provide Exceptional Support
                        </Heading>
                        <div className="mt-2 flex flex-1 justify-end gap-5 md:flex-wrap md:self-stretch">
                          <Link to="/contacts">
                            <Button
                              shape="round"
                              rightIcon={
                                <Img src="images/img_heart.svg" alt="Heart" className="my-1 h-[16px] w-[16px]" />
                              }
                              className="min-w-[194px] gap-2 rounded-[5px] px-[34px] font-semibold capitalize sm:px-4"
                            >
                              Contact Us
                            </Button>
                          </Link>
                          <Link to="/getstarted">
                            <Button
                              color="gray_300"
                              variant="outline"
                              shape="round"
                              rightIcon={
                                <Img src="images/img_heart.svg" alt="Heart" className="my-1 h-[16px] w-[16px]" />
                              }
                              className="min-w-[196px] gap-2 rounded-[5px] !border px-[34px] font-semibold capitalize sm:px-4"
                            >
                              Get Started
                            </Button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="relative ml-[120px] mt-[-70px] flex flex-col items-center rounded-[44px] bg-amber-700 p-5 md:ml-0 sm:hidden lg:mt-[-40px]">
                <Img src="images/img_search.svg" alt="Search Image" className="h-[50px] w-[50px] sm:hidden" />
              </div>
            </div>

            {/* our team section */}
            <OurTeamSection />
          </div>
          <Footer className="mt-[120px]" />
        </div>
      </div>
    </>
  );
}
