import { Button, TextArea, Heading, Input, Img, BreadcrumbLink, Breadcrumb, BreadcrumbItem } from "../../components";
import ContactUsSection from "../../components/ContactUsSection";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import React, { Suspense } from "react";
import { useContact } from "./useContact";
import { Link } from "react-router-dom";

const contactOptionsList = [
  { callUsText: "Call Us", phoneNumberPlaceholder: process.env.REACT_PUBLIC_CONTACT_NO, imgPath: "images/img_call.svg" },
  { callUsText: "Mail Us", phoneNumberPlaceholder: process.env.REACT_APP_PUBLIC_EMAIL_ADDRESS, imgPath: "images/email.svg" },
  { callUsText: "Visit Us", phoneNumberPlaceholder: process.env.REACT_APP_PUBLIC_ADDRESS, imgPath: "images/marker.svg" },
];

export default function ContactsPage() {
  const { submitForm, handleChange, formData, validationErrors } =
    useContact();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    submitForm();
  };

  return (
    <>
      <div className="flex w-full flex-col gap-[120px] bg-white-a700 lg:gap-[90px] md:gap-[90px] sm:gap-[60px]">
        <div className="flex flex-col items-center gap-[120px] lg:gap-[90px] md:gap-[90px] sm:gap-[60px]">
          <div className="h-[462px] self-stretch bg-[url(/public/images/img_mask_group.png)] bg-cover bg-no-repeat lg:h-auto md:h-auto">
            <div className="flex flex-col items-center bg-blue_gray-900_b2 py-[18px]">
              <div className="container-xs mt-3.5 flex flex-col items-start lg:px-5 md:px-5">
                <Header className="self-stretch sm:flex-col" />
                <Heading
                  size="heading3xl"
                  as="h1"
                  className="mt-[146px] w-[74%] text-[40px] font-semibold leading-[46px] text-white-a700 lg:w-full lg:text-[34px] md:w-full md:text-[34px] sm:text-[32px]"
                >
                  Contact Us
                </Heading>
                <div className="mt-3.5 h-[3px] w-[5%] rounded-[1px] bg-amber-700" />
                <Breadcrumb
                  separator={<Img src="images/img_crumbs.svg" alt="Arrow Right" className="h-[10px] w-[10px]" />}
                  className="mt-[118px] flex flex-wrap items-center gap-2.5 self-stretch"
                >
                  <BreadcrumbItem>
                    <Link to="/" className="lg:text-[15px]">
                      <Heading size="headings" as="h2" className="text-[18px] font-semibold text-white-a700" style={{ color: '#ECA30C' }} >
                        Home
                      </Heading>
                    </Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem isCurrentPage>
                    <BreadcrumbLink className="lg:text-[15px]">
                      <Heading size="headings" as="h3" className="text-[18px] font-semibold text-white-a700">
                        Contact Us
                      </Heading>
                    </BreadcrumbLink>
                  </BreadcrumbItem>
                </Breadcrumb>
              </div>
            </div>
          </div>
          <div className="container-xs lg:px-5 md:px-5">
            <div>
              <div className="flex items-start md:flex-col">
                <div className="mt-4 flex w-full flex-col items-start">
                  <Heading size="headingmd" as="h2" className="text-[20px] font-semibold text-teal-600">
                    Contact Us
                  </Heading>
                  <Heading
                    size="heading3xl"
                    as="h3"
                    className="mt-1.5 w-[92%] text-[40px] font-semibold leading-[46px] text-blue_gray-900 lg:w-full lg:text-[34px] md:w-full md:text-[34px] sm:text-[32px]"
                  >
                    Get in Touch
                  </Heading>
                  <div className="mt-3.5 h-[3px] w-[10%] bg-amber-700" />
                  <Heading
                    as="h4"
                    className="mt-3.5 w-[92%] text-[16px] font-normal leading-[22px] text-gray-600 lg:w-full lg:text-[13px] md:w-full"
                  >
                    We&#39;re here to help! Whether you have queries about our services, need support, or just want to
                    say hello, please feel free to get in touch.
                  </Heading>
                  <div className="mr-[354px] mt-[30px] flex flex-col gap-6 self-stretch md:mr-0">
                    <Suspense fallback={<div>Loading feed...</div>}>
                      {contactOptionsList.map((d, index) => {
                        if (d.callUsText === "Mail Us" && !process.env.REACT_APP_PUBLIC_EMAIL_ADDRESS) {
                          return (
                            <></>
                          );
                        }
                        return (
                        <ContactUsSection {...d} key={"contactList" + index} />
                      )})}
                    </Suspense>
                  </div>
                </div>
                <div className="flex w-full flex-col items-end self-center">
                  <div className="flex w-[92%] flex-col items-center gap-4 rounded-[10px] bg-white-a700 p-[50px] shadow-xs lg:w-full md:w-full md:p-5 sm:p-4">
                    <Heading
                      size="headingxl"
                      as="h5"
                      className="text-[27px] font-semibold leading-8 text-blue_gray-900 lg:text-[22px] md:text-[21px]"
                    >
                      Fill the Form Below
                    </Heading>
                    <form onSubmit={handleSubmit} className="container flex flex-col gap-6 p-8 shadow-lg rounded-lg bg-white">
                      <div className="flex flex-col gap-4">
                        {/* Row for Name and Email */}
                        <div className="flex flex-wrap gap-4">
                          {/* Name Field */}
                          <label className="flex-1 min-w-[200px]">
                            <Heading as="h6" className="text-[16px] font-medium text-blue_gray-900">Name</Heading>
                            <Input
                              shape="round"
                              type="text"
                              name="name"
                              value={formData.name}
                              onChange={handleChange}
                              placeholder="Your Name"
                              className="rounded-[5px] !border px-5 w-full"
                            />
                            {validationErrors.name && <span className="text-red-500">{validationErrors.name}</span>}
                          </label>

                          {/* Email Field */}
                          <label className="flex-1 min-w-[200px]">
                            <Heading as="h6" className="text-[16px] font-medium text-blue_gray-900">Email</Heading>
                            <Input
                              shape="round"
                              type="email"
                              name="email"
                              value={formData.email}
                              onChange={handleChange}
                              placeholder="Your Email"
                              className="rounded-[5px] !border px-5 w-full"
                            />
                            {validationErrors.email && <span className="text-red-500">{validationErrors.email}</span>}
                          </label>
                        </div>

                        <div className="flex flex-wrap gap-4">
                          <label className="flex-1 min-w-[200px]">
                            <Heading as="h6" className="text-[16px] font-medium text-blue_gray-900">Phone</Heading>
                            <Input
                              shape="round"
                              type="tel"
                              name="phoneNumber"
                              value={formData.phoneNumber}
                              onChange={handleChange}
                              placeholder="Your Phone"
                              className="rounded-[5px] !border px-5 w-full"
                            />
                            {validationErrors.phoneNumber && <span className="text-red-500">{validationErrors.phoneNumber}</span>}
                          </label>

                          <label className="flex-1 min-w-[200px]">
                            <Heading as="h6" className="text-[16px] font-medium text-blue_gray-900">Subject</Heading>
                            <Input
                              shape="round"
                              type="text"
                              name="subject"
                              value={formData.subject}
                              onChange={handleChange}
                              placeholder="Your Subject"
                              className="rounded-[5px] !border px-5 w-full"
                            />
                          </label>
                        </div>

                        <label className="flex flex-col gap-1.5">
                          <Heading as="h6" className="text-[16px] font-medium text-blue_gray-900">Message</Heading>
                          <TextArea
                            shape="round"
                            name="query"
                            value={formData.query}
                            onChange={handleChange}
                            placeholder="Enter Your Message"
                            className="rounded-[5px] !border px-5 text-gray-600 w-full"
                          />
                          {validationErrors.query && <span className="text-red-500">{validationErrors.query}</span>}
                        </label>

                        <Button
                          shape="round"
                          className="rounded-[5px] px-6 font-semibold capitalize text-white bg-amber-700 hover:bg-amber-800"
                          type="submit"
                        >
                          Send Message
                        </Button>
                      </div>

                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}


